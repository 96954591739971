@font-face {
  font-family: 'Cocon';
  font-style: light;
  font-display: swap;
  font-weight: 100;
  src:
    local('CoconOT'),
    local('CoconOT'),
    url(/static/media/CoconOT-Light.13a5746e.woff) format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Cocon';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('CoconOT'),
    local('CoconOT'),
    url(/static/media/CoconOT-Regular.0b4aacb0.woff) format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Cocon';
  font-style: bold;
  font-display: swap;
  font-weight: 800;
  src:
    local('CoconOT'),
    local('CoconOT'),
    url(/static/media/CoconOT-Bold.5e8e6397.woff) format('woff'); /* Modern Browsers */
}

