@font-face {
  font-family: 'Cocon';
  font-style: light;
  font-display: swap;
  font-weight: 100;
  src:
    local('CoconOT'),
    local('CoconOT'),
    url('./CoconOT-Light.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Cocon';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('CoconOT'),
    local('CoconOT'),
    url('./CoconOT-Regular.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Cocon';
  font-style: bold;
  font-display: swap;
  font-weight: 800;
  src:
    local('CoconOT'),
    local('CoconOT'),
    url('./CoconOT-Bold.woff') format('woff'); /* Modern Browsers */
}
